
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.section{
  justify-self: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  padding: 0 80px;
  max-width: 1230px;
  @media #{$md}{
    flex-direction: column;
    padding: 0px 40px 0;
  }
}
.title{
  font-weight: 800;
  font-size: 32px;
  line-height: 1.5;
  text-align: left;
  color: #2E2E2E;
  flex: 0 1 33%;
  display: flex;
  padding-right: 30px;
  @media #{$md}{
    padding-right: 0;
    width: 100%;
    font-size: 20px;
  }
}
.cardRight{
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media #{$md}{
    font-size: 20px;
  }
  @media #{$sm}{
    grid-template-columns: 1fr;
  }
}

.cardWrap{
  display: flex;
  background-color: #F5F7FA;
  padding: 55px 30px;
  &:hover{
    box-shadow: 0 0 4px rgba(0,0,0, 0.15);
  }
  &:after{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
  @media #{$md}{
    padding: 60px 30px;
    &:after{
      display: none;
    }
  }
  @media #{$sm}{
    padding: 40px 30px;
  }
}


.card{
  display: flex;
  position: relative;
  text-align: left;
  box-sizing: border-box;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.03em;
  @media #{$md}{
    font-size: 12px;
  }
}
