
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.home-search{
  margin-bottom: 94px;
  position: relative;
  @media #{$md}{
    margin-bottom: 136px;
  }

  @media #{$xs}{
    margin-bottom: 160px;
  }
}


