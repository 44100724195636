
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.steps{
  font-family: Montserrat;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.08);
  border-radius: 1px;
  grid-row-start: 6;
  grid-row-end: 7;
  width: 86%;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  max-width: 1364px;
  top: 100%;
  @media only screen and (max-width: 1440px) {
    width: 89%;
  }
  @media #{$md} {
    margin-top: 10px;
    margin: 0 40px;
    width: calc(100% - 80px);
  }
  @media #{$xl} {
    width: 80%;
  }
  &__wrap{
    background-color: #ffffff;
    justify-content: center;
    justify-self: center;
    padding: 0 16px;
    margin: 0;
  }
  &__number{
    position: relative;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.4;
    color: $cBrandPrimary;
    opacity: 0.8;
    @media #{$md} {
      padding-bottom: 8px;
      &:before{
        content:'';
        height: 1px;
        position: absolute;
        bottom: 0;
        background-color: $cUIdark;
        display: block;
        left: -12px;
        right: -12px;
      }
    }
    &.isActive{
      @media #{$md} {
        &:before{
          background-color: $cBrandPrimary;
        }
      }
    }
  }
  &__description{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.4;
    color: #687689;
    @media #{$md} {
      position: absolute;
      width: calc(100% - 40px);
      margin-top: 10px;
      left: 20px;
    }
  }
  &__title{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.2;
    color: #2E2E2E;
  }
  &__item{
    min-height: 130px;
    position: relative;
    @media #{$md} {
      position: static;
    }
    @media #{$sm}{
      min-height: 142px;
    }
    &:before{
      content: "";
      position: absolute;
      height: 0px;
      right: 0px;
      top: 0;
      opacity: 0.7;
      height: 100%;
      width: 1px;
      background: $cUIdark;
      bottom: 0;
      margin: auto;
      @media #{$md}{
        content: none;
      }
    }
    &:last-of-type{
      &:before{
        content: none;
      }
    }
  }
}

.arrow{
  width: 36px;
  height: 18px;
  fill: $cUIdark;
  opacity: 0.8;
  @media #{$md}{
    width: 20px;
    height: 10px;
  }
}
