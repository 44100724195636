
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.container {
  max-width: 1620px;
  width: 100%;
  padding: 0 90px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    padding: 0 50px;
  }
  @media (max-width: 1024px) {
    padding: 0 30px;
  }
  @media (max-width: 480px) {
    padding: 0 20px;
  }
}

.title {
  font-weight: bold;
  font-size: 36px;
  line-height: 44px;
  color: $black;
  margin-bottom: 44px;
  @media (max-width: 1024px) {
    margin-bottom: 32px;
  }
  @media (max-width: 768px) {
    font-size: 24px;
    line-height: 28px;
  }
}

.button {
  display: block;
  margin: 50px auto 0;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #3570c9;
  padding: 14px;
  width: 220px;
  border-radius: 1px;
  background: transparent;
  border: 1px solid #3570c9;
  transition: 0.45s ease-out;
  &:hover {
    cursor: pointer;
    color: #fff;
    background: #0d4fb2;
    border-color: #0d4fb2;
  }
}

.box {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 24px;
  column-gap: 24px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 16px;
    column-gap: 16px;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }

  &_item {
    display: flex;
    flex-direction: column;
    transition: 0.45s ease-out;
    filter: brightness(100%);
    &:hover {
      filter: brightness(125%);
    }

    &.box_item_accent {
      grid-column: 3 / 5;
      @media (max-width: 1024px) {
        grid-column: 1 / 3;
      }
      @media (max-width: 574px) {
      }
      @media (max-width: 480px) {
        grid-column: 1;
      }

      > div {
        height: 100%;
        min-height: 250px;
        @media (max-width: 480px) {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column-reverse;
          align-items: flex-start;
          justify-content: flex-start;
          > div {
            align-items: flex-start;
          }
        }
      }

      &:first-child {
        grid-column: 1 / 3;
        @media (max-width: 480px) {
          grid-column: 1;
        }
      }
    }
  }
  &_item_card {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    padding: 16px;
    width: 100%;
    height: 250px;
    color: #ffffff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    flex: none;
    @media (max-width: 480px) {
      grid-template-columns: 1fr;
      height: 220px;
    }

    &_title {
      font-weight: 600;
      font-size: 22px;
      line-height: 26px;
      max-width: 390px;
      @media (max-width: 574px) {
        font-size: 16px;
        line-height: 24px;
      }
      @media (max-width: 480px) {
        padding-top: 16px;
      }
    }

    &_labs {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      flex-direction: column;
      grid-column-start: 2;
      row-gap: 8px;
      @media (max-width: 480px) {
        grid-column-start: 1;
        grid-row-start: 1;
      }
    }
    &__price {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      padding: 6px 12px;
      background: #24ae3a;
      border-radius: 3px;
    }
    &__label {
      display: inline-block;
      font-size: 12px;
      line-height: 16px;
      padding: 4px 8px;
      border-radius: 3px;
    }
    &__top {
      font-weight: bold;
      background: #fb4b53;
    }
    &__newService {
      background: #e88b48;
    }
    &__newProgram {
      background: #8a3ffc;
    }
    &__exclusive {
      background: #fb4b53;
    }
  }
  &_item_info {
    display: flex;
    flex-direction: column;
    height: 100%;
    &_title {
      font-weight: 600;
      font-size: 18px;
      line-height: 26px;
      color: #2e2e2e;
      padding-top: 16px;
      @media (max-width: 574px) {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      row-gap: 8px;
      padding-top: 10px;
      width: 100%;
      margin-bottom: 0;
      margin-top: auto;

      @media (max-width: 1580px) {
        flex-wrap: wrap;
      }
      @media (max-width: 574px) {
        padding-top: 18px;
      }
      @media (max-width: 480px) {
        padding-top: 10px;
      }

      &_title {
        font-weight: 500;
        font-size: 12px;
        line-height: 140%;
        color: #8293aa;
        padding-right: 10px;
        @media (max-width: 480px) {
          width: 100%;
        }
      }

      &_rate {
        display: flex;
        align-items: center;
        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 140%;
          color: #212223;
          @media (max-width: 1024px) {
            display: none;
          }
          @media (max-width: 768px) {
            display: inline-block;
          }
        }
        &_box {
          display: flex;
          column-gap: 1px;
          padding-left: 6px;
        }
      }
    }
  }
}

.imgBg{
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  img{
    object-fit: cover;
  }
}
