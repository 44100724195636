
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.cardSubscribe{
  background-image: url(/img/home/subscribeBgImg.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 152px 60px 150px 145px;
  @media #{$lg}{
    padding-top: 130px;
    padding-bottom: 130px;
  }
  @media #{$md}{
    padding-top: 100px;
    padding-bottom: 100px;
    background-image: url(/img/home/subscribeBgImg-tab.jpg);
  }
  @media #{$sm}{
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 60px;
  }
}
.subscribeTitle{
  font-weight: 800;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  max-width: 570px;
  color: $white;
  @media #{$md} {
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }
  @media #{$sm} {
    font-size: 24px;
    line-height: 29px;
    grid-column-start: 2;
  }
}
.subsPreferences{
  font-size: 14px;
  line-height: 17px;
  color: $white;
  padding: 16px 0 22px 0;
  @media #{$md} {
    width: 360px;
    text-align: center;
    padding: 12px 0 24px 0;
  }
  @media #{$mds}{
    width: 100%
  }
  @media #{$sm} {
    padding: 16px 0 26px 0;
  }
}
.formSubscribeStyle{
  & :global(.ant-form-item-explain.ant-form-item-explain-error) {
    position: absolute;
    left: 0;
    bottom: -1.6em;
  }
  @media #{$md} {
    display: block;
    &:after {
      content: '';
      display: table;
      clear: both;
    }
    & :global(.ant-form-item:first-child) {
      float: left;
      :global(.ant-input) {
        height: 44px;
      }
    }
    & :global(.ant-form-item) {
      float: right;
    }
  }
}
