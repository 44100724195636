
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.cardNews{
  background-color: $field;
  box-sizing: border-box;
  padding: 64px 6% 62px 30px;
  display: flex;
  justify-content: flex-end;
  @media #{$xl}{
    padding-right: 40px;
  }
  @media #{$md}{
    padding-left: 14%;
    padding-right: 14%;
  }
}
.wrap{
  width: 100%;
  max-width: 640px;
  @media #{$md}{
    max-width: 100%;
  }
}
.linkList{
  margin-top: 42px;
  margin-bottom: 36px;
  @media #{$md}{
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media #{$sm}{
    margin-top: 26px;
    margin-bottom: 40px;
  }
  a {
    display: grid;
    grid-template-columns: 1fr 30px 16px;
    box-sizing: border-box;
    margin-bottom: 30px;
    position: relative;
    transition: all 0.3s;
    &:hover {
      color: $accentHover;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:before {
      content: '';
      position: absolute;
      top: 5px;
      right: 30px;
      width: 20px;
      height: 9px;
      background: url(/img/arrow/arrow-right-accent.svg);
      background-size: 20px auto;
      background-position: center;
      background-repeat: no-repeat;
      @media #{$sm}{
        right: 10px;
      }
    }
    @media #{$sm}{
      grid-template-columns: 1fr 18px 16px;
    }
  }
}
.field{
  width: 100%;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 0;
  padding-right: 12px;
}
.styleLinkGeneral{
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: $accent;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: fit-content;
  position: relative;
  padding-right: 18px;
  transition: all 0.3s;
  &:hover {
    color: $accentHover;
    svg {
      path {
        fill: $accentHover;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 7px;
    height: 12px;
    transform: translateY(-50%);
    background: url(/img/arrow/arrow-right-next.svg);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  svg {
    display: inline-block;
    margin-left: 10px;
  }
}
.title{
  font-weight: 500;
  font-size: 36px;
  line-height: 44px;
  text-align: left;
  margin-bottom: 0;
  @media #{$sm}{
    font-size: 20px;
    font-weight: 600;
  }
}
