
        @import "src/assets/styles/_variables.scss";
        $url: "https://static.iworld.com";
      
.section{
  display: flex;
  align-items: center;
  @media #{$md}{
    flex-wrap: wrap;
  }
  > div {
    width: 50%;
    @media #{$md}{
      width: 100%;
    }
  }
}
